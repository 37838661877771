<template>
    <v-container class="px-0">
        <v-data-table
            :headers="headersBrowse"
            :items="itemsBrowse"
            @pagination="applyPagination"
            :server-items-length="totalItemsResults"
            :items-per-page.sync="itemsPerPage" 
            :page.sync="page" 
            :server-items="loadData"
            :loading="loading"
            class="elevation-1"
            :footer-props="{
                'disable-items-per-page': true,
            }"
        >
        <template v-slot:[`item.logInTime`]="{item}">
            <span>{{formatLoginTime(item)}}</span> 
        </template>
        <template v-slot:[`item.logOutTime`]="{item}">
            <span>{{formatLogoutTime(item)}}</span> 
        </template>
        </v-data-table>
    </v-container>
</template>

<script>
export default {
    name:'BrowseList',
    data(){
        return{
            page: 1,
            itemsPerPage: 10,
            totalItemsResults: 0,
            loading: true,
            headersBrowse:[
                {text:this.$t('name'),value:'fullName'},
                {text:this.$t('email'),value:'email'},
                {text:this.$t('logInTime'),value:'logInTime'},
                {text:this.$t('logOutTime'),value:'logOutTime'},
                {text:this.$t('ip'),value:'ip'},
                {text:this.$t('browser'),value:'browser'},
                {text:this.$t('browserVersion'),value:'browserVersion'},
                {text:this.$t('platform'),value:'platform'},
                {text:this.$t('locationInfo'),value:'locationInfo'},
            ],
            itemsBrowse:[],
        }
    },
    methods:{
        applyPagination(){
            this.loadData();
        },
        loadData() {
            this.loading = true;
            this.$store.dispatch('dashboard/applyLoading')
            const obj={
                page: this.page,
                itemsPerPage: this.itemsPerPage
            }
            this.$store.dispatch('user/fetchBrowseListServerSide',obj)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsBrowse = response.data.responseMsg;
                this.totalItemsResults =response.data.count
                this.loading = false;
            })
            .catch((err)=>{
                console.log(err)
                this.loading = false;
            })
        },
        formatLoginTime(item){
            return item.logInTime.replace('T',' ').substring(0,19)
        },
        formatLogoutTime(item){
            return item.logOutTime!=null?item.logOutTime.replace('T',' ').substring(0,19):''
        }
    },
}
</script>